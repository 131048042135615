@font-face {
  font-family: 'Red Hat Display';
  src: url("../../public/fonts/RedHatDisplay-Medium.ttf");
  font-weight: 300;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url("../../public/fonts/RedHatDisplay-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url("../../public/fonts/RedHatDisplay-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url("../../public/fonts/RedHatDisplay-Bold.ttf");
  font-weight: 800;
}